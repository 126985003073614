<template>
  <div class="profile-container kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
    <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
      <div class="kt-grid__item kt-app__toggle kt-app__aside">
        <portlet>
          <template slot="body">
            <div class="kt-widget kt-widget--user-profile-1">
              <div class="kt-widget__head">
                <div class="kt-widget__media">
                  <b-img-lazy :src="currentAdmin.avatar_url || '/assets/media/misc/loading.svg'" blank-src="/assets/media/misc/loading.svg" alt="image" />
                </div>
                <div class="kt-widget__content">
                  <div class="kt-widget__section">
                    <a href="#" class="kt-widget__username">
                      {{ currentAdmin.nickname }}
                      <i class="flaticon2-correct kt-font-success"></i>
                    </a>
                    <span class="kt-widget__subtitle">
                      {{ currentAdmin.name }}
                    </span>
                  </div>
                  <div class="kt-widget__action"></div>
                </div>
              </div>
              <div class="kt-widget__body">
                <div class="kt-widget__content">
                  <div class="kt-widget__info">
                    <span class="kt-widget__label">تېلفۇن نۇمۇرىڭىز:</span>
                    <a href="#" class="kt-widget__data">{{ currentAdmin.phone }}</a>
                  </div>
                </div>
                <div class="kt-widget__items">
                  <router-link :to="{name: 'edit.profile'}" active-class="kt-widget__item--active" class="kt-widget__item">
                    <span class="kt-widget__section">
                      <span class="kt-widget__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"></path>
                          </g>
                        </svg> </span>
                      <span class="kt-widget__desc">
                        ئارخىپ تەھرىرلەش
                      </span>
                    </span>
                  </router-link>
                  <router-link :to="{name: 'change.password'}" active-class="kt-widget__item--active" class="kt-widget__item">
                    <span class="kt-widget__section">
                      <span class="kt-widget__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"/>
                              <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3"/>
                              <path d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z" fill="#000000"/>
                          </g>
                        </svg>
                      </span>
                      <span class="kt-widget__desc">
                         پارول ئۆزگەرتىش
                      </span>
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </portlet>
      </div>
      <div class="kt-grid__item kt-grid__item--fluid kt-app__content">
        <transition name="fade-in-up">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  import { mapGetters } from "vuex";
  import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs.module";
  export default {
    name: "index",
    components: { Portlet },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئارخىب باشقۇرۇش", route: "/profile" },
        { title: "ئارخىبىم" }
      ]);
    },
    data(){
      return {
      };
    },
    computed: {
      ...mapGetters(["currentAdmin"])
    }
  }
</script>

<style scoped>

</style>
